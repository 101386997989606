var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-list',{ref:"listings",staticClass:"locationList"},_vm._l((_vm.locations),function(searchLocation,index){return _c('q-item',{key:`${searchLocation.locationId}-${index}`},[_c('q-item-section',{staticClass:"full-width"},[_c('div',{staticClass:"row wrap items-center full-width"},[_c('div',{staticClass:"col-12 col-md-12 hlocationListDetail"},[_c('div',{staticClass:"row q-mb-sm"},[_c('div',{staticClass:"col-xs-8 title"},[_c('div',{staticClass:"flex-inline q-mr-sm"},[_vm._v("\n                "+_vm._s(searchLocation.name))])]),(searchLocation.distance)?_c('div',{staticClass:"col-xs-4 text-right"},[_vm._v("\n              "+_vm._s(searchLocation.distance)+" mi\n            ")]):_vm._e()]),_c('q-item-label',{staticClass:"q-mb-sm",attrs:{"caption":""},domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatAddress(searchLocation.address))}}),_c('q-item-label',{staticClass:"hlocationListIcon"},_vm._l((searchLocation.fulFillments.filter(
                (fulFillment) => fulFillment.available
              )),function(fulFillment,index){return _c('span',{key:`${fulFillment.fulfillmentMethodID}-${index}`,class:{ disabled: fulFillment.configuration.isComingSoon }},[_c('SvgIcon',{attrs:{"icon":fulFillment.codeName}}),_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark","offset":[0, 10]}},[_vm._v("\n                "+_vm._s(fulFillment.displayName)+"\n              ")])],1)}),0),_c('div',{staticClass:"row q-mt-md hlocationListAction"},[_c('div',{staticClass:"col-xs-12"},[(
                  searchLocation.locationId === _vm.currentLocationId &&
                  _vm.searchedFulfillment?.codeName == _vm.selectedFulfillmentCodeName
                )?_c('span',{staticClass:"location-badge"},[_vm._v("\n                My Store\n              ")]):_vm._e(),(_vm.checkIsComingSoon(searchLocation))?_c('q-btn',{staticClass:"q-mr-sm",attrs:{"disabled":"","unelevated":"","no-caps":"","rounded":"","color":"tertiary"}},[_vm._v("\n                Coming Soon!\n              ")]):(
                  searchLocation.showSetPreffered &&
                  (searchLocation.locationId !== _vm.currentLocationId ||
                    _vm.searchedFulfillment?.codeName !==
                      _vm.selectedFulfillmentCodeName)
                )?_c('q-btn',{staticClass:"q-mr-sm",attrs:{"unelevated":"","no-caps":"","rounded":"","color":"primary","label":"Set as my store"},on:{"click":function($event){return _vm.handleClickSetMyStore(searchLocation)}}}):_vm._e(),_c('q-btn',{staticClass:"text-underline",attrs:{"unelevated":"","no-caps":"","rounded":"","flat":"","color":"tertiary","label":"Store Details","to":{
                  name: 'Location',
                  params: { locationId: searchLocation.locationId },
                }},on:{"click":_vm.handleClickStoreDetail}})],1)])],1)])])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }