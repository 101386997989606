<template>
  <q-list ref="listings" class="locationList">
    <q-item
      v-for="(searchLocation, index) in locations"
      :key="`${searchLocation.locationId}-${index}`"
    >
      <!-- :class="{ 'location-error': index == 2 }" -->
      <q-item-section class="full-width">
        <div class="row wrap items-center full-width">
          <div class="col-12 col-md-12 hlocationListDetail">
            <div class="row q-mb-sm">
              <div class="col-xs-8 title">
                <div class="flex-inline q-mr-sm">
                  {{ searchLocation.name }}</div
                >
                <!-- <div
                  class="text-negative flex-inline location-title-error vertical-middle"
                >
                  <q-icon name="info"></q-icon> Delivery location is too far
                </div> -->
                <!-- <span v-if="searchLocation.locationId === currentLocationId">
                  My Store
                </span> -->
              </div>
              <div class="col-xs-4 text-right" v-if="searchLocation.distance">
                {{ searchLocation.distance }} mi
              </div>
            </div>

            <q-item-label
              caption
              class="q-mb-sm"
              v-html="$options.filters.formatAddress(searchLocation.address)"
            />

            <q-item-label class="hlocationListIcon">
              <span
                v-for="(
                  fulFillment, index
                ) in searchLocation.fulFillments.filter(
                  (fulFillment) => fulFillment.available
                )"
                :key="`${fulFillment.fulfillmentMethodID}-${index}`"
                :class="{ disabled: fulFillment.configuration.isComingSoon }"
              >
                <SvgIcon :icon="fulFillment.codeName" />
                <!-- <q-btn
                  v-if="fulFillment.configuration.isComingSoon"
                  unelevated
                  no-caps
                  label="Coming Soon!"
                  padding="0"
                  class="text-underline text-primary no-hover"
                /> -->
                <q-tooltip
                  max-width="180px"
                  content-class="bg-dark"
                  :offset="[0, 10]"
                >
                  {{ fulFillment.displayName }}
                </q-tooltip>
              </span>
            </q-item-label>

            <div class="row q-mt-md hlocationListAction">
              <div class="col-xs-12">
                <span
                  class="location-badge"
                  v-if="
                    searchLocation.locationId === currentLocationId &&
                    searchedFulfillment?.codeName == selectedFulfillmentCodeName
                  "
                >
                  My Store
                </span>
                <q-btn
                  v-if="checkIsComingSoon(searchLocation)"
                  disabled
                  unelevated
                  no-caps
                  rounded
                  color="tertiary"
                  class="q-mr-sm"
                >
                  Coming Soon!
                </q-btn>
                <q-btn
                  unelevated
                  no-caps
                  rounded
                  color="primary"
                  class="q-mr-sm"
                  v-else-if="
                    searchLocation.showSetPreffered &&
                    (searchLocation.locationId !== currentLocationId ||
                      searchedFulfillment?.codeName !==
                        selectedFulfillmentCodeName)
                  "
                  label="Set as my store"
                  @click="handleClickSetMyStore(searchLocation)"
                />
                <q-btn
                  unelevated
                  no-caps
                  rounded
                  flat
                  color="tertiary"
                  label="Store Details"
                  class="text-underline"
                  :to="{
                    name: 'Location',
                    params: { locationId: searchLocation.locationId },
                  }"
                  @click="handleClickStoreDetail"
                />
              </div>
            </div>
          </div>
        </div>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LocationList',
  props: {
    locations: {
      type: Array,
      default: new Array(),
    },
    searchedFulfillment: {
      type: Object,
      default: () => {
        return {}
      },
    },
    resultByKeyword: {
      type: String,
      default: '',
    },
    deliveryAddress: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters('cart', ['currentCartDetails']),
  },
  methods: {
    checkIsComingSoon(location) {
      let curFulFillment = location?.fulFillments.find(
        (fulFillment) =>
          fulFillment.codeName == this.searchedFulfillment?.codeName
      )

      return curFulFillment?.configuration?.isComingSoon || false
    },
    handleClickStoreDetail() {
      this.changeDialogState({
        dialog: 'changeLocation',
        val: false,
      })
      /* check and run only if location detail page is currently open */
      if (this.$route.name == 'Location') {
        this.changeDialogState({
          dialog: 'reserveTimeslot',
          val: false,
        })
      }
    },
    handleClickSetMyStore(location) {
      this.$store.dispatch('location/setAsMyStore', {
        location: location,
        fulfillment: this.searchedFulfillment,
        selectedZipCode: this.resultByKeyword,
        deliveryAddress: this.deliveryAddress,
      })
    },
  },
}
</script>
<style lang="scss">
.locationList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  .location-badge {
    margin-right: 10px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: normal;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    color: #008a03 !important;
    border: 1px solid currentColor;
    border-radius: 15px;
  }
  .q-item {
    border-radius: 24px;
    flex: 0 1 100%;
    width: 100%;
    padding: 15px;
    @media (min-width: 860px) {
      flex: 0 1 50%;
      width: 50%;
    }
    @media (min-width: 1366px) {
      flex: 0 1 33.33%;
      width: 33.33%;
    }

    .locationListInner {
      position: relative;
      flex-direction: row;
      padding: 15px;
      background: #fafafa;
      border: 1px solid #cccccc;
      border-radius: 5px;
      @media (min-width: 860px) {
        padding: 30px;
      }
      .locationListTitle {
        padding-bottom: 15px;
        font-size: 20px;
        font-weight: bold;
        color: #0f141a;
        @media (max-width: 850px) {
          padding-right: 45px;
          font-size: 18px;
        }
        @media (min-width: 1681px) {
          font-size: 24px;
        }
        span {
          margin: 0 10px;
          padding: 5px 15px;
          font-size: 14px;
          line-height: normal;
          font-weight: bold;
          display: inline-block;
          vertical-align: middle;
          color: #008a03;
          border: 1px solid currentColor;
          border-radius: 15px;
        }
      }
      .text-caption {
        padding-bottom: 15px;
        font-size: 14px;
        color: #0f141a;
        @media (min-width: 1681px) {
          font-size: 16px;
        }
      }
      .q-btn--flat {
        font-size: 16px;
      }
      .q-item__section--side {
        font-size: 16px;
        color: #0f141a;
        @media (min-width: 1366px) {
          font-size: 20px;
        }
        @media (min-width: 1681px) {
          font-size: 24px;
        }
        @media (max-width: 850px) {
          position: absolute;
          top: 20px;
          right: 10px;
          font-size: 12px;
        }
      }
    }
  }

  &.q-list {
    margin: 0 -1%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .q-item__section {
      justify-content: flex-start;
      height: 100%;
      padding-bottom: 50px;
      position: relative;
      .hlocationListAction {
        position: absolute;
        bottom: 0;
      }
    }
    @media (max-width: 1023px) {
      margin: 0;
    }
  }
  .full {
    &.q-item {
      width: 98%;
    }
  }

  .q-item {
    margin: 1%;
    padding: 15px;
    flex: 0 1 98%;
    width: 98%;
    border: 1px solid #cccccc;
    border-radius: 5px;
    @media (min-width: 600px) {
      width: 48%;
      flex: 0 1 48%;
    }
    @media (min-width: 1024px) {
      width: 31.33%;
      flex: 0 1 31.33%;
    }
    .q-item__section--avatar {
      min-width: 30px;
    }
    .readLocation {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
    @media (min-width: 1681px) {
      padding: 25px;
    }
    &.disabled {
      .hlocationListDetail {
        .q-item__label {
          font-weight: normal;
          color: #ec7a7a;
        }
      }
    }
    .q-btn {
      font-size: 14px;
    }
  }
  .hlocationListDetail {
    .title {
      font-size: 15px;
      font-weight: bold;
      color: #0f141a;
      @media (min-width: 1681px) {
        font-size: 18px;
      }
      span {
        margin: -3px 10px 0;
        padding: 3px 12px;
        font-size: 11px;
        line-height: normal;
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        color: #008a03;
        border: 1px solid currentColor;
        border-radius: 15px;
      }
    }
    .text-right {
      font-size: 13px;
      color: #0f141a;
      @media (min-width: 1681px) {
        font-size: 15px;
      }
    }
    .text-caption {
      font-size: 12px;
      line-height: 1.5 !important;
      font-weight: normal;
      color: #0f141a;
      @media (min-width: 1681px) {
        font-size: 14px;
      }
    }
  }
  .hlocationListIcon {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    > span {
      cursor: pointer;
      &.comingsoon {
        text-align: center;
        min-width: 80px;
        i {
          display: block;
          margin: 3px auto;
        }
      }
    }
    i {
      width: 16px;
      height: 16px;
      margin: 3px;
      padding: 5px;
      font-size: 10px;
      color: $primary;
      color: var(--q-color-primary) !important;
      border: 1px solid $primary;
      border: 1px solid var(--q-color-primary) !important;
      border-radius: 50%;
      @media (max-width: 1023px) {
        width: 12px;
        height: 12px;
        margin: 2px;
        padding: 5px;
        svg {
          width: 8px;
        }
      }
      svg {
        width: 12px;
        fill: currentColor;
      }
    }
  }
}
</style>
